import React, { useEffect, useState } from "react";
import styles from "./favourite.module.scss";
import { HTTP_CLIENT } from "../../../../utils/services";
import Slider from "../../../_Common/Slider/slider";
import Popup from "../../../../Components/_Common/Modal/Modal.jsx";
const FavouriteCoin = ({ SData }) => {
  const [tokenList, setTokenList] = useState();
  const [Open, setOpen] = useState();
  const [detailData, setdetailData] = useState();
  const fatchData = async () => {
    let res = await HTTP_CLIENT.get("native-token/get-all-native-tokens");
    let sortedData = res?.data?.sort((a, b) => a.orderIndex - b.orderIndex);
    setTokenList(sortedData);
  };
  const visibleToken = tokenList?.filter(
    (item) => item?.isVisibleOnWeb === true
  );

  //
  useEffect(() => {
    fatchData();
  }, []);

  const handleOpen = (data) => {
    setdetailData(data);
    setOpen(true);
  };

  // const transformText=(value:string)=>{
  // const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  const URL_REGEX = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
  function transformText(value) {
    const words = value?.split(" ");
    return (
      <p>
        {words?.map((word) => {
          return word?.match(URL_REGEX) ? (
            <>
              <a
                href={`http://${
                  word?.split("/")[2] ? word?.split("/")[2] : word
                }`}
                target="_blank"
                style={{ color: "#40a1dc" }}
              >
                {word}
              </a>{" "}
            </>
          ) : (
            word + " "
          );
        })}
      </p>
    );
  }

  return (
    <>
      <Popup
        visible={Open}
        // btn
        onClose={() => setOpen(false)}
        outerStyle={{
          background: "white",
          maxWidth: "900px",
        }}
        showModal2={true}
      >
        <DetailToken data={detailData} />
      </Popup>
      <div className={styles.container}>
        <Slider team={3}>
          {visibleToken?.map((item, key) => (
            <div className={styles.container} key={key}>
              <div className={styles.wrapper}>
                <>
                  <div className={styles.coinimage} data-aos="flip-left">
                    <img src={item?.icon?.url} alt="Coins" />
                  </div>
                  <div className={styles.textWrapper}>
                    <div className={styles.heading}>{item?.displayName}</div>
                    <div className={styles.price} data-aos="zoom-out-up">
                      <img src={item?.displaySymbol} alt="" />
                      <p>
                        {item?.coinSymbol == "Q"
                          ? Number(
                              (item?.price * item?.multiplier)?.toFixed(
                                item?.decimals
                              )
                            )?.toLocaleString()
                          : Number(
                              (item?.price * item?.multiplier)?.toFixed(
                                item?.decimals
                              )
                            )?.toLocaleString()}
                      </p>
                    </div>
                    <div className={styles.subHeading} data-aos="zoom-in">
                      <p>{transformText(item.description)}</p>
                      <br />
                      <button onClick={() => handleOpen(item)}>
                        About {item?.shortName}
                      </button>
                    </div>
                  </div>
                </>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default FavouriteCoin;

export const DetailToken = ({ data }) => {
  return (
    <>
      <div className={styles.popupContainer}>
        <h1 className={styles.heading}> About {data?.shortName}</h1>
        <p>{data?.description}</p>
      </div>
    </>
  );
};
