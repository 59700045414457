import React, { useEffect, useState } from "react";
import Input from "../../../../../_Common/input/input";
import styles from "./buyMarketplace.module.scss";
import { useHistory } from "react-router-dom";
import { Router } from "react-router-dom";
import { WaveClone } from "../../../../../_Common/loader/Loader";
import { useAlert } from "react-alert";
import { BASE_URL } from "../../../../../../utils/AppConstants";
import axios from "axios";
import { setupAxios } from "../../../../../../utils/services";
import stylesmodal from "../../../../../_Common/checkChangePasswordModal/openaccount.module.scss";
import Modal from "../../../../../_Common/Modal/Modal";
import CQRComponent from "../../../../../_Common/CQRComponent/CQRComponent";

const BuyMarketplace = ({ data, id, setpopupvisible, priceOfShare }) => {
  const history = useHistory();
  const [FNFTData, setFNFTData] = useState(null);
  const [loanCheck, setLoanCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [unitsState, setunitsState] = useState(false);
  const [Data, setData] = useState();

  const alert = useAlert();

  const handleSubmit = () => {
    setSubmitLoading(true);
    if (!amount) {
      alert.show("Please enter amount first", { type: "error" });
      setSubmitLoading(false);
      return;
    }
    const params = {
      adId: id,
      numShares: +amount,
    };
    if (params) {
      setData(params);
      setSubmitLoading(false);
      setunitsState(true);
    }
  };
  const handlePostData = async () => {
    setLoading(true);

    let authToken = localStorage.getItem("authToken");

    try {
      const res = await axios.post(`${BASE_URL}marketplace/exchange`, Data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });

      alert.show(
        "The units acquired have been added to your vault successfully",
        { type: "success" }
      );
      history.push("/marketplace");
      setLoading(false);
      setAmount("");
    } catch (err) {
      setLoading(false);
      alert.show(err?.response?.data?.message, { type: "error" });
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    let lenght = value.toString().split(".")[1]?.length;
    if (lenght > 8) {
      return;
    } else {
      setAmount(value);
    }
  };
  return (
    <>
      {!unitsState ? (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.heading}>Get Units Now</div>
            <div>
              <input
                style={{
                  backgroundColor: "#2d3143",
                  border: " 1px solid rgba(71, 71, 71, 0.6)",
                }}
                onChange={handleChange}
                type="number"
                step="any" // Allows decimals
                placeholder="Enter number of Units"
                value={amount}
                onWheel={(e) => e.target.blur()} // Prevents scroll value changes
              />
              ;
            </div>

            {submitLoading ? (
              <button className={styles.loadingBtn}>
                <WaveClone />
              </button>
            ) : (
              <button className={styles.btn} onClick={() => handleSubmit()}>
                Submit
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className={stylesmodal.container}>
          <div className={stylesmodal.textsection}>
            <label>
              Are you sure you want to acquire {Number(amount)?.toFixed(2)}{" "}
              {data?.nfttokens?.name} Tokens by remitting <CQRComponent />
              {Number(amount * priceOfShare)?.toFixed(2)} CQRUS?
            </label>
          </div>
          <div className={stylesmodal.btnsection}>
            {loading ? (
              <button className={stylesmodal.Delete} disabled>
                <WaveClone />
              </button>
            ) : (
              <button
                className={stylesmodal.Delete}
                type="submit"
                onClick={handlePostData}
              >
                Yes
              </button>
            )}
            <button
              disabled={loading ? true : false}
              className={stylesmodal.Delete}
              onClick={() => setpopupvisible(false)}
            >
              No
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BuyMarketplace;
// () => history.push("/cqr-Vest")
