import { store } from "../redux/store";

export const generateChatId = (uid, passedId) => {
  return uid < passedId
    ? uid.concat("**", passedId)
    : passedId.concat("**", uid);
};
///=---------data format---------//
const LOG_TYPE = {
  WITHDRAW: "withdraw",
  WITHDRAW_DEPOSIT: "withdrawDeposit",
  WIRE_DEPOSIT: "wireDeposit",
  PAYPAL_DEPOSIT: "paypalDeposit",
  COINQVEST_DEPOSIT: "coinqvestDeposit",
  CRYPTO_SWAP: "cryptoSwap",
  ASSETS_SWAP: "assetToAssetSwap",
  ASSETS_DEBIT: "debitAssets",
  CREDIT_ASSETS: "creditAssets",

  SEND_ASSETS: "sendAssets",
  NFT_ACQUIRE: "nftAcquire",
  NFT_PURCHASE: "nftPurchase",
  SUBSCRIPTION_PURCHASE: "subscriptionPurchase",
  SUBSCRIPTION_RENEW: "subscriptionRenew",
  SHEET_DEBIT: "sheetDebit",
  SHEET_CREDIT: "sheetCredit",
  CRYPTO_ACTIVATE: "cryptoWalletActivate",
  LEGACY_WITHDRAW_DEPOSIT: "legacyWithdrawDeposit",
  LEGACY_DEPOSIT: "legacyDeposit",
};
const formatNumbersByDecimals = (value, decimals) => {
  return parseFloat(String(value)).toFixed(decimals);
};
export const getTransactionData = (payload) => {
  const {
    from,
    to,
    fromToken,
    toToken,
    fromUser,
    toUser,
    toAmount,
    amount,
    memo,
    userSubscription,
    coin,
    nftTokenDetails,
  } = payload.data;
  console.log(toAmount, "dsmfisfisjnik");
  let title = "",
    message = "",
    memos = "",
    displayAmount = amount;
  let type = "deposit";

  switch (payload.data.type) {
    case LOG_TYPE.WITHDRAW:
      title = `Withdraw`;
      type = "withdraw";

      break;

    case LOG_TYPE.WIRE_DEPOSIT:
      title = `Top-Up By Direct Wire`;
      type = "deposit";
      displayAmount = toAmount;
      break;
    case LOG_TYPE.WITHDRAW_DEPOSIT:
      title = `Redeem`;
      type = "withdraw";
      displayAmount = toAmount;
      break;

    case LOG_TYPE.PAYPAL_DEPOSIT:
      title = `Top-Up By Paypal`;
      type = "deposit";
      displayAmount = toAmount;

      break;
    case LOG_TYPE.LEGACY_WITHDRAW_DEPOSIT:
      title = `Legacy Redeem`;
      type = "withdraw";
      displayAmount = toAmount;

      break;
    case LOG_TYPE.LEGACY_DEPOSIT:
      title = `Legacy Top-Up`;
      type = "deposit";
      displayAmount = toAmount;

      break;

    case LOG_TYPE.COINQVEST_DEPOSIT:
      title = `Top-Up By CoinQVest`;
      type = "deposit";
      displayAmount = toAmount;

      break;
    case LOG_TYPE.CRYPTO_SWAP:
      const cryptoCoins = store?.getState()?.currency?.currencyInfo?.wallets;
      const currentCryptoCoin = cryptoCoins?.find((coin) => coin?._id === from);
      type = "deposit";
      title = `Crypto Swap`;
      displayAmount = toAmount;
      message = `Swapped ${amount} ${currentCryptoCoin?.coinSymbol} to ${toToken[0]?.shortName}`;

      break;

    case LOG_TYPE.ASSETS_SWAP:
      title = `Swap`;
      type = "deposit";
      displayAmount = toAmount;

      message = `Swapped ${formatNumbersByDecimals(
        amount,
        fromToken[0]?.decimals
      )} ${fromToken[0]?.shortName} to ${toToken[0]?.shortName}`;
      if (from === payload.currentToken?._id) {
        type = "withdraw";
        displayAmount = amount;
      }
      break;

    case LOG_TYPE.SEND_ASSETS:
      type = "deposit";
      title = `Receive`;
      displayAmount = amount;
      memos = payload?.data?.memo;
      message = `From: ${fromUser[0]?.email}`;

      if (from === payload.userId) {
        type = "withdraw";
        title = `Send`;
        message = `To: ${toUser[0]?.email}`;
        displayAmount = amount;
        memos = payload?.data?.memo;
      }

      break;
    case LOG_TYPE.CREDIT_ASSETS:
      type = "deposit";
      title = `Credit`;
      displayAmount = amount;
      message = payload.data.message;

      break;
    case LOG_TYPE.ASSETS_DEBIT:
      type = "deposit";
      title = `Receive`;
      displayAmount = toAmount;

      message = `Debit by admin`;

      if (from === payload.userId) {
        type = "withdraw";
        title = `Debit`;
        message = payload.data.message;

        displayAmount = amount;
      }

      break;
    case LOG_TYPE.SHEET_CREDIT:
      type = "deposit";
      title = `Credit`;
      displayAmount = amount;
      message = `Credit by admin`;

      break;

    case LOG_TYPE.SHEET_DEBIT:
      type = "withdraw";
      title = `Debit`;
      displayAmount = amount;
      message = `Debit by admin`;

      break;

    case LOG_TYPE.NFT_ACQUIRE:
      type = "deposit";
      title = `Acquired`;
      displayAmount = toAmount;
      message = `Acquired ${nftTokenDetails[0]?.name}`;

      if (coin === payload.currentToken?._id) {
        type = "withdraw";
        title = `Acquired Opportunity Token`;
        displayAmount = amount;
      }

      break;

    case LOG_TYPE.NFT_PURCHASE:
      type = "deposit";
      title = `Acquired From Marketplace`;
      displayAmount = toAmount;

      if (from === payload.userId) {
        type = "withdraw";
        title = `Exchanged`;
        displayAmount = toAmount;
      }

      break;

    case LOG_TYPE.SUBSCRIPTION_PURCHASE:
      type = "withdraw";
      title = `Subscription Acquire`;
      message = `Acquired ${userSubscription[0]?.title}`;

      displayAmount = amount;
      break;

    case LOG_TYPE.SUBSCRIPTION_RENEW:
      type = "withdraw";
      title = `Subscription Renew`;
      message = `${userSubscription[0]?.title} package subscription renewed`;

      displayAmount = amount;
      break;

    default:
  }
  if (payload?.currentToken?.decimals) {
    displayAmount = formatNumbersByDecimals(
      displayAmount,
      payload.currentToken?.decimals
    );
  }
  return {
    title,
    type,
    message,
    displayAmount,
    memos,
  };
};
