import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BackTo from "../../../../../Assets/icons/backk.svg";
import { getAssetsToAssetsSawap } from "../../../../../redux/action/sawapAction";
import { postWithdrawalData } from "../../../../../services/paymentServices";
import { WITHDRAWAL_NETWORK_TYPES } from "../../../../../utils/withdrawalNetworkTypes";
import DropDown from "../../../../_Common/DropDown/dropDown";
import Modal from "../../../../_Common/Modal/Modal";
import stylesmodal from "../../../../_Common/checkChangePasswordModal/openaccount.module.scss";
import InputField from "../../../../_Common/input/input";
import { WaveClone } from "../../../../_Common/loader/Loader";
import WithdrawalHistory from "../WithdrawalHistory";
import styles from "./withdrawalDirectwire.module.scss";
import DirectwireAddress from "./directwireAddress";
import Remmitanceplus from "../../../../../Assets/icons/remmitanceplus.svg";
import {
  getDirectwireAddress,
  handleWithdrawDirectwire,
  handleWithdrawLegacy,
} from "../../../../../services/withdrawService";
import CQRComponent, {
  CQRAComponent,
} from "../../../../_Common/CQRComponent/CQRComponent";

const WithdrawalDirectwire = () => {
  const history = useHistory();
  const alert = useAlert();
  const location = useLocation();
  const dispatch = useDispatch();
  const [swap, setSwap] = useState("withdraw");
  const sawapSelector = useSelector((state) => state?.sawap);

  const [loading, setloading] = useState(false);
  const [show, setShow] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [filterQData, setfilterQData] = useState("");
  const options = filterQData;
  const [selected, setSelected] = useState({ name: "Select Token" });
  const [CurrentValue, setCurrentValue] = useState();
  const [network, setNetwork] = useState({ name: "Select Account" });
  const [networkOpetion, setNetworkOption] = useState("");
  const [valueGet, setValueGet] = useState("");
  const [allAddress, setAllAddress] = useState("");

  const Network = networkOpetion;
  const currencySelector = useSelector((state) => state.currency);
  const { getnetworkData } = useSelector((state) => state.currency);
  const [state, setState] = useState({
    selectedToken: null,
    amount: "",
    selectedNetwork: null,

    address: null,

    //stellar
    account: null,
    memo: null,
    memoType: null,
    asset: null,

    //CBU/CVU (ARS)
    cbu: null,
    cuitPersonal: null,
    cuitCompany: null,
  });
  const [active, setActive] = useState(0);

  const onChangeState = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value }));
  };
  const isValidated = () => {
    if (!state.selectedToken) {
      alert.show("Please select a token to continue", { type: "error" });
      return false;
    } else if (+state.amount == 0 || !state.amount) {
      alert.show("Please enter a valid amount value", { type: "error" });
      return false;
    } else if (+state.amount > +selected?.balance) {
      alert.show("Insufficient Balance", { type: "error" });
      return false;
    }

    return true;
  };

  //----------withdraw directwire-----------------//

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidated()) {
      try {
        setloading(true);

        let payload = {
          withdrawAmount: +valueGet,
          amount: +state?.amount,
          token: selected?._id,
          withdrawalAddressData: network,
          withdrawalAddress: network?._id,
        };

        if (state?.asset) {
          payload["targetAsset"] = state?.asset;
        }
        if (location?.state?.detail?.type === "sdira") {
          const response = await handleWithdrawLegacy(payload);
          alert.show(response?.data?.message, { type: "success" });
        } else {
          const response = await handleWithdrawDirectwire(payload);
          alert.show(response?.data?.message, { type: "success" });
        }

        setState({});
        setNetwork({ name: "Select Destination" });
        setSelected({});
        setOpenModal(false);
        history.go(-2);
        setloading(false);
      } catch (err) {
        const error = err?.response?.data?.message;
        setloading(false);
        setState({});
        setSelected({ name: "Select Token" });
        setNetwork({ name: "Select Destination" });

        setOpenModal(false);

        alert.show(error ? error : "Something went wrong. Please try again.", {
          type: "error",
        });
      }
    }
  };

  const handleOpenModal = async () => {
    if (isValidated()) {
      setOpenModal(true);
    }
  };

  useEffect(() => {
    if (selected?.shortName == "CQR Dollar") {
      setCurrentValue(Number(state?.amount) * 1);
    } else {
      setCurrentValue(Number(state?.amount) * Number(selected?.price));
    }
    setNetworkOption(getnetworkData);
    let filterLiquidity = currencySelector?.currencyInfo?.nativeWallets.filter(
      (item) => item?.type === "liquidity"
    );
    let filterAssets = currencySelector?.currencyInfo?.nativeWallets.filter(
      (item) => item?.type === "asset"
    );
    let filterSdira = currencySelector?.currencyInfo?.nativeWallets.filter(
      (item) => item?.type === "sdira"
    );
    if (location?.state?.detail?.type === "liquidity") {
      setfilterQData(filterLiquidity);
    } else if (location?.state?.detail?.type === "asset") {
      setfilterQData(filterAssets);
    } else {
      setfilterQData(filterSdira);
    }
  }, [network, state?.amount, selected]);
  const valueChanger = () => {
    let data = {
      selected: selected,
      amount: "1",
    };
    try {
      dispatch(getAssetsToAssetsSawap(data, swap));
    } catch (error) {}
  };

  //-------------all Withdraw address-----------------//

  const allWithdrawAddress = async () => {
    try {
      const res = await getDirectwireAddress();
      setAllAddress(res?.data?.data);
    } catch (error) {}
  };
  useEffect(() => {
    allWithdrawAddress();
    valueChanger();
  }, [selected]);
  useEffect(() => {
    setValueGet(sawapSelector?.assetSwaoInfo * state.amount);
  }, [state.amount]);
  useEffect(() => {
    setValueGet(sawapSelector?.assetSwaoInfo * state.amount);
  }, [sawapSelector]);

  return (
    <>
      <Modal visible={openModal} onClose={() => setOpenModal(false)} btn>
        <div className={stylesmodal.container}>
          <div className={stylesmodal.textsection}>
            <label>
              Are you sure you want to finalize this redeem request?
            </label>
          </div>
          <div className={stylesmodal.btnsection}>
            {loading ? (
              <button className={stylesmodal.Delete} disabled>
                <WaveClone />
              </button>
            ) : (
              <button
                className={stylesmodal.Delete}
                type="submit"
                disabled={loading ? true : false}
                onClick={handleSubmit}
              >
                Confirm
              </button>
            )}
            <button
              disabled={loading ? true : false}
              className={stylesmodal.Delete}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={show}
        btn
        onClose={() => {
          setShow(false);
        }}
      >
        <DirectwireAddress setAllAddress={setAllAddress} setShow={setShow} />
      </Modal>

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <img
            src={BackTo}
            className={styles.bactoImg}
            alt="back"
            onClick={() => history.goBack()}
            width={40}
          />
          <div className={styles.heading}></div>
          <div className={styles.borderRight}>
            <label>Redeem</label>

            <form>
              <div className={styles.addressWrapper}>
                <label>Add Address</label>
                <img
                  onClick={() => setShow(true)}
                  src={Remmitanceplus}
                  alt="plus"
                />
              </div>
              <DropDown
                padding="14px 20px"
                selected={selected}
                setSelected={(res) => {
                  onChangeState("selectedToken", res);
                  setSelected(res);
                }}
                name="Select Token"
                options={options}
                fixed={selected?.decimals}
                img
                imgShow
                BalanceStatus
                Balance={selected?.balance}
              />
              <InputField
                value={state?.amount ? state.amount : ""}
                inputName="Enter Amount"
                name="Enter Amount"
                type="number"
                onclick={(e) => onChangeState("amount", e.target.value)}
              />

              <DropDown
                padding="14px 20px"
                selected={network}
                setSelected={(res) => {
                  onChangeState("selectedNetwork", res);
                  setNetwork(res);
                }}
                name="Select Address"
                options={allAddress}
                imgShow
              />
            </form>
            <div className={styles.infoWrapper}>
              <div className={styles.withdrawInfo}>
                <p>Redeem Amount</p>
                <span>
                  {state?.amount} {selected?.displayName}
                </span>
              </div>
              <div className={styles.withdrawInfo}>
                <p>You will get</p>
                <span>{valueGet > 0 ? valueGet?.toFixed(2) : ""}</span>
              </div>
              <div className={styles.withdrawInfo}>
                <p>Credited in</p>
                <span>10-15 Working Days</span>
              </div>
            </div>
            <div className={styles.btnsection}>
              <button className={styles.btn} onClick={handleOpenModal}>
                Get Now
              </button>
              <p>
                This amount will be deducted from your C<CQRComponent />R Vault
                account after the redeem process is completed.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.historyWraper}>
          <WithdrawalHistory
            setActive={setActive}
            directwire={true}
            location={location}
          />
        </div>
      </div>
    </>
  );
};

export default WithdrawalDirectwire;
